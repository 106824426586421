export default class VideoSectionLauncher {
  constructor () {
    this.init()
  }

  init () {
    const sections = document.querySelectorAll('[data-section]')

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        const video = document.getElementById('home-video')

        if (video && entry.isIntersecting) {
          video.play()
        } else if (video) {
          video.pause()
        }
      })
    }, { threshold: 0.5 }) // Trigger when 50% of the section is visible

    sections.forEach(section => observer.observe(section))
  }
}
